body {
  margin: 0;
  font-family: "minion-3", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: whitesmoke;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.bg-nav {
background-color: #F2ACCD;
}
.btn-primary {
  background-color: #ABCCF2 !important;
  border-color: #92BDEE !important;
  color: black;
}
.btn-primary:hover, .btn-primary:active {
  background-color: #2C7FDE !important;
  border-color: #2070CC !important;
  color: white;
}
.btn-outline-dark:hover, .btn-outline-dark:active {
  background-color: #ABCCF2 !important;
  color: black;
}
.list-group-item {
  background-color: #F7EDED;
}
.lightOpaqueBackground {
  background-color: #F7EDED;
}
.opaqueBackground {
  background-color: #F1E1E2;
}
.headerTextLight {
  margin: 0;
  /* font-family: "brandon-grotesque", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "myriad-pro", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  color: #0077ff;
}

h1, h4, h5, h6 {
  font-family: "myriad-pro-condensed", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-weight: 600;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logoText {
  margin: 0;
  font-family: "brandon-grotesque", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.headerText {
  margin: 0;
  /* font-family: "brandon-grotesque", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 400; */
  font-family: "myriad-pro-condensed", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.modalUnpadded {
  padding-left: 0px !important;
  margin: -1px 0px 0 -1px!important;
  width: calc(100vw + 1px);
  height: auto;
}

.modalFullWidthDialog .modal-content {
  max-height: calc(100vh + 1px);
}

.modalFullWidthDialog {
  width: 100% !important;
  height: calc(100vh + 1px) !important;
  margin: 0 !important;
  max-width: none !important;
  max-height: calc(100vh + 1px);
  padding: 0;
  overflow-y: hidden;
  overflow-x: hidden;
}

.modalFullHeightContent {
  margin-top: 0;
  margin-right: auto;
  margin-bottom: 0;
  margin-left: auto;
  padding: 0;
  min-height: calc(100vh + 1px);
  max-height: calc(100vh + 1px);
  border-radius: 0;
}

.modal-open {
  padding-right: 0px!important;
}
.react-datepicker__day--selected {
  background-color: #F2ACCD !important;
}